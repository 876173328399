<template>
    <div class="talentTraining_page">
        <BannerImg :bannerImgUrl="formData.bannerImgUrl"></BannerImg>
        <div class="module1">
            <CourseTitle :title="formData.automationRevolutionName" contText=""> </CourseTitle>
            <div class="content main Between flex-wrap">
                <div class="list" :class="index % 2 == 0 ? 'list_fl' : ''"
                    v-for="(item, index) in formData.automatedPersonnelAutomationRevolutions" :key="index">
                    <img :src="item.automationRevolutionImgUrl" alt="">
                    <div class="list_name">{{ item.automationRevolutionName }}</div>
                </div>
            </div>
        </div>
        <div class="module2">
            <CourseTitle :title="formData.learningAutomationName" contText=""> </CourseTitle>
            <div class="content ">
                <div class="main Between">
                    <div class="list flex-center column"
                        v-for="(item, index) in formData.automatedPersonnelLearningAutomations" :key="index">
                        <div class="list_name">{{ item.learningAutomationName }}</div>
                        <div class="list_text">{{ item.learningAutomationIntroduce }}</div>
                        <img :src="item.learningAutomationImgUrl" alt="">
                    </div>

                </div>
            </div>
        </div>
        <div class="module3">
            <CourseTitle :title="formData.suitableCrowdName" contText=""> </CourseTitle>
            <div class="content ">
                <div class="main Between">
                    <div class="list " v-for="(item, index) in formData.automatedPersonnelSuitableCrowds" :key="index">
                        <img :src="item.suitableCrowdImgUrl" alt="">
                        <div class="list_name">{{ item.suitableCrowdName }} </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="module4">
            <CourseTitle :title="formData.payGoingUpName" contText=""> </CourseTitle>
            <div class="content ">
                <img :src="formData.payGoingUpImgUrl" alt="">
            </div>
        </div>
        <div class="module5">
            <CourseTitle :title="formData.hotCourseName" isWhite=" 1">
            </CourseTitle>
            <div class="content ">
                <div class="main flex flex-wrap">
                    <img class="list_img" :src="formData.hotCourseImgUrl" alt="">
                    <div class="list " :class="index % 2 != 0 ? 'list_fr' : ''"
                        v-for="(item, index) in formData.automatedPersonnelHotCourses" :key="index">
                        <div class="listName display">{{ item.hotCourseName }}</div>
                        <div class="richText" v-html="item.hotCourseIntroduce"> </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module6">
            <CourseTitle :title="formData.plcDataName" contText=""> </CourseTitle>
            <div class="content ">
                <div class="main">
                    <ul>
                        <li class="list_A flex-center" v-for="(item, index) in formData.automatedPersonnelPlcDatas"
                            :key="index">
                            <div class="label display">{{ item.plcDataName }}</div>
                            <ul class="flex">
                                <li class="list_B ">
                                    <img v-for="(items, index) in item.plcDataImgUrl" :key="index" class="list_img"
                                        :src="items" alt="">
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="formData_div Between">
                    <div class="list">
                        <input type="text" v-model="params.userName" placeholder="请输入您的姓名">
                    </div>
                    <div class="list">
                        <input type="text" maxlength="11" v-model="params.phoneNumber" placeholder="请输入您的手机号">
                    </div>
                    <!-- <div class="list">
                        <el-select v-model="value" placeholder="请选择">
                            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div> -->
                    <div class="list">
                        <input type="text" v-model="params.typeQualification" placeholder="请输入您要领取的资料">
                    </div>
                    <div class="btn display pointer" @click="submitBtn">
                        立即领取
                    </div>
                </div>
            </div>
        </div>
        <div class="module7">
            <CourseTitle :title="formData.golgaAdvantageName" contText=""> </CourseTitle>
            <div class="content ">
                <div class="main flex">
                    <div class="list flex-center column" v-for="(item, index) in formData.automatedPersonnelGolgaAdvantages"
                        :key="index">
                        <img :src="item.golgaAdvantageImgUrl" alt="">
                        <div class="list_name">{{ item.golgaAdvantageName }}
                        </div>
                        <div class="list_text">{{ item.golgaAdvantageIntroduce }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module8">
            <CourseTitle :title="formData.practicalTeachingName" :contText="formData.practicalTeachingIntroduce"
                isWhite="1"> </CourseTitle>
            <div class="content ">
                <div class="main flex">
                    <div class="leftMenuBar">
                        <div class="list display pointer" @click="changeBtn(item, index)"
                            :class="activeIndex == index ? 'active' : ''"
                            v-for="(item, index) in formData.automatedPersonnelPracticalTeachings" :key="index">
                            {{ item.practicalTeachingName }}</div>
                    </div>
                    <div class="right_img">
                        <div class="carousel_box">
                            <el-carousel :interval="3000" indicator-position="none">
                                <el-carousel-item v-for="(item, index) in rightMenuImg" :key="index">
                                    <img class="carousel_img pointer" :src="item" alt="" />
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="module9">
            <CourseTitle :title="formData.antiquityProjectName" contText=""> </CourseTitle>
            <div class="content ">
                <div class="navBr display">
                    <div class="list display pointer" @click="changeNav(item, index)"
                        :class="activeIndexA == index ? 'active' : ''"
                        v-for="(item, index) in formData.automatedPersonnelAntiquityProjects" :key="index">
                        {{ item.antiquityProjectName }}</div>
                </div>
            </div>
            <div class="carousel">
                <div class="carousel_box">
                    <el-carousel :interval="5000">
                        <el-carousel-item v-for="(item, index) in carouselImg" :key="index">
                            <div class="el_carousel_cont flex">
                                <img :src="item" alt="">
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
        </div>
        <div class="module10">
            <CourseTitle :title="formData.faqName" contText=""> </CourseTitle>
            <div class="content ">
                <div class="main Between flex-wrap">
                    <div class="list flex-center" v-for="(item, index) in formData.jobSalutationQuestions" :key="index">
                        <img src="../../assets/img/civilServant/problem.png" alt="">
                        <div class="list_text">
                            {{ item.questionName }}
                        </div>
                        <div class="btn display pointer" @click="service"> 立即咨询</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BannerImg from '@/components/bannerImg'
import CourseTitle from './components/courseTitle.vue'
export default {
    components: {
        BannerImg,
        CourseTitle,
    },
    data() {
        return {
            activeIndex: 0,
            activeIndexA: 0,
            list: [
                {},
                {},
                {},
                {},
            ],
            rightMenuImg: [],
            options: [],
            value: '',
            formData: {},
            carouselImg: [],
            params: {
                userName: '',
                phoneNumber: '',
                courseId: 1,
                courseType: 17,
                courseName: '工业自动化人才培训',
                email: '',
                companyName: '',
                questionContent: '',
                typeQualification: '',
                certificationProject: '',
                locationEnterprise: '',
                dateEstablishment: '',
            },
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.api.automatedPersonnelApi().then(res => {
                if (res.data.automatedPersonnelPlcDatas.length != 0) {
                    res.data.automatedPersonnelPlcDatas.forEach(item => {
                        item.plcDataImgUrl = item.plcDataImgUrl.split(',')
                    })
                }
                if (res.data.automatedPersonnelPracticalTeachings.length != 0) {
                    res.data.automatedPersonnelPracticalTeachings.forEach(item => {
                        item.practicalTeachingImgUrl = item.practicalTeachingImgUrl.split(',')
                    })
                    this.rightMenuImg = res.data.automatedPersonnelPracticalTeachings[0].practicalTeachingImgUrl
                }
                if (res.data.automatedPersonnelAntiquityProjects.length != 0) {
                    res.data.automatedPersonnelAntiquityProjects.forEach(item => {
                        item.antiquityProjectImgUrl = item.antiquityProjectImgUrl.split(',')
                    })
                    this.carouselImg = res.data.automatedPersonnelAntiquityProjects[0].antiquityProjectImgUrl
                }
                this.formData = res.data
            })
        },
        //联系客服
        service() {
            document.getElementById('nb-frame-chat-box').style.display = 'block'
        },
        changeBtn(item, index) {
            this.activeIndex = index
            this.rightMenuImg = item.practicalTeachingImgUrl
        },
        changeNav(item, index) {
            this.activeIndexA = index
            this.carouselImg = item.antiquityProjectImgUrl
        },
        submitBtn() {
            if (this.params.userName == '') {
                return this.$message.error('请输入您的姓名')
            }

            if (!/^[0-9]{11}$/.test(this.params.phoneNumber)) {
                return this.$message.error('请正确输入手机号')
            }
            if (this.params.typeQualification == '') {
                return this.$message.error('请选择课程')
            }
            this.api.addCourseApply(this.params).then((res) => {
                if (res.code == 0) {
                    this.$message.success('领取成功！')
                    this.params = {
                        userName: '',
                        phoneNumber: '',
                        courseId: 1,
                        courseType: 16,
                        courseName: '工业自动化人才培训',
                        email: '',
                        companyName: '',
                        questionContent: '',
                        typeQualification: '',
                        certificationProject: '',
                        locationEnterprise: '',
                        dateEstablishment: '',
                    }
                } else {
                    this.$message.success(res.msg)
                }
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.talentTraining_page {
    .module1 {
        height: 874px;
        background: url('../../assets/img/talentTraining/bg.png') no-repeat;
        background-size: 100% 100%;

        .main {
            height: 690px;
            background: url('../../assets/img/talentTraining/bg1.png') no-repeat;
            background-size: 100% 100%;

            .list_fl {
                margin-right: 508px
            }

            .list {
                width: 340px;

                img {
                    width: 340px;
                    height: 160px;
                }

                .list_name {
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 18px;
                    margin-top: 14px;
                    text-align: center;
                }
            }
        }
    }

    .module2 {
        height: 661px;

        .main {
            margin-top: 30px;

            .list {
                width: 260px;
                height: 406px;
                background: url('../../assets/img/talentTraining/bg2.png') no-repeat;
                background-size: 100% 100%;

                .list_name {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #FFFFFF;
                    line-height: 24px;
                    margin-top: 29px;
                }

                .list_text {
                    width: 208px;
                    height: 92px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    line-height: 24px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    margin-top: 22px;
                }

                img {
                    width: 240px;
                    height: 150px;
                    margin-top: 18px;
                }
            }
        }
    }

    .module3 {
        height: 640px;
        background: url('../../assets/img/talentTraining/bg3.png') no-repeat;
        background-size: 100% 100%;

        .main {
            margin-top: 30px;

            .list {
                width: 380px;
                height: 368px;
                background: #FFFFFF;
                box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);

                img {
                    width: 380px;
                    height: 260px;
                }

                .list_name {
                    height: 108px;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                    text-align: center;
                    margin-top: 30px;
                    padding: 0 40px;
                }



            }
        }
    }

    .module4 {
        height: 782px;

        img {
            width: 1200px;
            height: 540px;
            margin-top: 19px;
        }
    }

    .module5 {
        width: 100%;
        height: 702px;
        background: url('../../assets/img/civilServant/bg5.png') no-repeat;
        background-size: 100% 100%;

        .main {
            margin-top: 48px;
            position: relative;

            .list_img {
                width: 345px;
                height: 345px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -182px;
                margin-left: -172px;
            }

            .list:nth-child(2) {
                .richText {
                    width: 288px;
                    height: 70px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 26px;

                }
            }

            .list:nth-child(3) {
                .richText {
                    width: 252px;
                    line-height: 26px;

                }
            }

            .list:nth-child(4) {
                .richText {
                    width: 294px;
                    line-height: 26px;

                }
            }

            .list:nth-child(5) {
                .richText {
                    width: 287px;
                    line-height: 26px;

                }
            }

            .list {
                width: 463px;
                height: 200px;
                background: #FAFAFA;
                border-radius: 5px;
                padding-top: 29px;
                margin: 0 65px;
                margin-bottom: 20px;

                .listName {
                    width: 333px;
                    height: 53px;
                    background: url('../../assets/img/civilServant/bg6.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-left: -9px;
                    margin-bottom: 12px;
                }

                .richText {
                    margin-left: 42px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 40px;
                }
            }

            .list_fr {
                .listName {
                    width: 333px;
                    height: 53px;
                    background: url('../../assets/img/civilServant/bg6s.png') no-repeat;
                    background-size: 100% 100%;
                    margin-left: 139px;
                    margin-bottom: 12px;
                }

                .richText {
                    margin-left: 146px;
                }
            }
        }
    }

    .module6 {
        padding-bottom: 90px;

        .main {
            margin-top: 30px;

            .list_A {
                margin-bottom: 21px;

                .label {
                    width: 96px;
                    height: 120px;
                    background: url('../../assets/img/talentTraining/bg9.png') no-repeat;
                    background-size: 100% 100%;
                    font-size: 20px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-top: -10px;
                    margin-right: 16px;
                }

                .list_B {


                    .list_img {
                        width: 200px;
                        height: 120px;
                        margin-top: -4px;
                        margin-right: 14px;
                    }
                }
            }
        }

        .formData_div {
            .list {
                width: 263px;
                height: 54px;
                background: #FFFFFF;
                border: 1px solid #A6A6A6;

                input {
                    width: 260px;
                    height: 52px;
                    padding-left: 20px;
                    box-sizing: border-box;
                }

                ::v-deep .el-input {
                    width: 260px;
                    height: 52px;
                    padding-left: 20px;
                    box-sizing: border-box;

                    .el-input__inner {
                        width: 230px;
                        height: 50px;
                        padding-left: 20px;
                        box-sizing: border-box;
                        border: none;
                    }
                }
            }

            .btn {
                width: 260px;
                height: 54px;
                background: #FF881E;
                border: 1px solid #FF881E;
                font-size: 24px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #FFFFFF;
            }


        }
    }

    .module7 {
        width: 100%;
        height: 570px;
        background: url('../../assets/img/talentTraining/bg8.png') no-repeat;
        background-size: 100% 100%;

        .main {
            margin-top: 79px;

            .list:last-child {
                margin-right: 0;
            }

            .list {
                width: 278px;
                height: 250px;
                background: #FFFFFF;
                box-shadow: 0px 1px 5px 0px rgba(51, 51, 51, 0.25);
                border-radius: 5px;
                margin-right: 28px;

                img {
                    width: 98px;
                    height: 98px;
                    margin-top: -49px;
                }

                .list_name {
                    font-size: 24px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #333333;
                    line-height: 24px;
                    margin-top: 40px;
                }

                .list_text {
                    width: 208px;
                    height: 39px;
                    font-size: 16px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    line-height: 24px;
                    text-align: center;
                    margin-top: 26px;
                }
            }
        }
    }

    .module8 {
        width: 100%;
        height: 740px;
        background: url('../../assets/img/talentTraining/bg7.png') no-repeat;
        background-size: 100% 100%;

        .main {
            margin-top: 40px;

            .leftMenuBar {
                .list {
                    width: 300px;
                    height: 134px;
                    border: 1px solid #DEDEDE;
                    font-size: 28px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                    margin-bottom: 20px;
                }

                .active {
                    background: #fff !important;
                    font-size: 28px;
                    font-family: Microsoft YaHei;
                    font-weight: bold;
                    color: #003C7E !important;
                }
            }

            .right_img {
                margin-left: 20px;

                .carousel_box {
                    width: 880px;
                    height: 440px;
                }

                .carousel_img {
                    width: 100%;
                    height: 100%;
                }

                ::v-deep.el-carousel__container {
                    height: 440px;
                }

                ::v-deep.el-carousel {
                    .el-carousel__container {
                        .el-carousel__arrow {
                            width: 48px;
                            height: 48px;
                            font-size: 24px;
                            background: rgba(51, 51, 51, 0.5);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }

    .module9 {
        padding-bottom: 87px;

        .navBr {
            margin-top: 10px;

            .list {
                width: 150px;
                height: 48px;
                background: #FAFAFA;
                border: 1px solid #DEDEDE;
                border-radius: 24px;
                font-size: 18px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #666666;
                margin: 0 10px;
            }

            .active {
                background: #003C7E !important;
                color: #FFFFFF !important;
            }
        }

        .carousel {
            width: 1400px;
            margin: 0 auto;

            .carousel_box {
                margin-top: 29px;

            }

            ::v-deep.el-carousel__container {
                height: 570px;
                display: flex;
                align-items: center;
                justify-content: center;

            }

            ::v-deep.el-carousel {
                .el-carousel__item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .el-carousel__container {
                    .el-carousel__arrow {
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        background: rgba(51, 51, 51, 0.5);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                }
            }

            .el_carousel_cont {
                width: 1200px;
                height: 570px;

                img {
                    width: 1200px;
                    height: 570px;
                }

            }
        }
    }

    .module10 {
        width: 100%;
        height: 650px;
        background: url('../../assets/img/civilServant/bg9.png') no-repeat;
        background-size: 100% 100%;

        .main {
            width: 1200px;
            background: #FFFFFF;
            box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            padding: 37px 40px 11px;
            box-sizing: border-box;
            margin-top: 30px;

            .list {
                width: 540px;
                height: 60px;
                background: #E2F0FB;
                border-radius: 5px;
                margin-bottom: 30px;

                img {
                    width: 26px;
                    height: 26px;
                    margin-left: 15px;
                }

                .list_text {
                    width: 324px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #333333;
                    margin-left: 18px;
                }

                .btn {
                    width: 126px;
                    height: 40px;
                    background: #FF881E;
                    border-radius: 20px;
                    font-size: 18px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #FFFFFF;
                }
            }
        }
    }
}
</style>